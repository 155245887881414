class YouTubeVideo extends HTMLElement {

    get videoCover() {
        return this.querySelector('[data-node="youtubeCover"]');
    }

    get videoSrc() {
        return this.videoCover.getAttribute('data-video');
    }

    get videoFrame() {
        return this.querySelector('[data-node="youtubeIframe"]');
    }

    hideCover() {
        this.videoCover.classList.add('fade-out');
    }

    addIframeSrc() {
        this.hideCover();
        this.videoFrame.src = this.videoSrc + '&autoplay=1';
        this.initPlayer();
    }

    initPlayer() {
        this.player = new YT.Player(this.videoFrame, {
            events: {
                'onStateChange': this.onPlayerStateChange.bind(this)
            }
        });
        
        this.videoId = this.videoFrame.getAttribute('id');
    }

    onPlayerStateChange(event) {
        const videoData = event.target.getVideoData();
        if (event.data === YT.PlayerState.PLAYING && !this.hasPlayed) {
            this.hasPlayed = true;
            this.videoDuration = this.player.getDuration(); 
            this.sendVideoEvent('play', videoData, 0);  
            this.trackProgress(videoData);
        } else if (event.data === YT.PlayerState.ENDED) {
            this.sendVideoEvent('ended', videoData, 100);
        }
    }

    trackProgress(videoData) {
        const intervals = [0.3, 0.5, 0.8];
        let tracked = { '0.3': false, '0.5': false, '0.8': false };

        this.progressInterval = setInterval(() => {
            const currentTime = this.player.getCurrentTime();
            const percentWatched = currentTime / this.videoDuration;

            intervals.forEach(interval => {
                if (!tracked[interval] && percentWatched >= interval) {
                    tracked[interval] = true;
                    this.sendVideoEvent('progress', videoData, interval * 100);
                }
            });

            if (percentWatched >= 1) {
                clearInterval(this.progressInterval);
            }
        }, 3000);
    }

    sendVideoEvent(status, videoData, percent) {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            event: 'gtm.video',
            'gtm.videoUrl': this.videoSrc,
            'gtm.videoTitle': videoData.title,
            'gtm.videoDuration': this.videoDuration,
            'gtm.videoPercent': percent,
            'gtm.videoStatus': status,
            'gtm.videoId': this.videoId 
        });
    }

    connectedCallback() {
        this.bind();
    }

    bind() {
        this.videoCover.addEventListener('click', e => this.addIframeSrc(e));
    }
}

customElements.define('c-youtube-video', YouTubeVideo);